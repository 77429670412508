import React, { useState } from "react";
import Flickity from "react-flickity-component";
import "flickity/dist/flickity.min.css";
import { graphql } from "gatsby";
import { useIdleTimer } from "react-idle-timer";
import spinner from "../../static/spinner.gif";
import { navigate } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import androidIcon from "../../src/images/android.png";
import iosIcon from "../../src/images/ios.png";
import SEO from "../components/SEO";

import back from "../images/goBack.png";
import UpdateContentModal from "../components/updateModal";

const ProductTemplate = ({ data }) => {
  const [showScreensaver, setShowScreensaver] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [animate, setAnimate] = useState(false);

  const updateContent = () => {
    setShowUpdateModal(false);
    if (window !== undefined) {
      window.location.reload();
    }
  };

  const handleOnIdle = event => {
    setAnimate(false);
    setShowScreensaver(true);
  };

  const handleOnActive = () => {
    setAnimate(true);
    setTimeout(() => {
      setShowScreensaver(false);
      navigate("/");
    }, 300);
  };

  const title = data.wp.post.title;
  const content = data.wp.post.properties.mainInfo.content;
  const icon = data.wp.post.properties.mainInfo.icon.mediaItemUrl;
  const adv = data.wp.post.properties.mainInfo.adv ? data.wp.post.properties.mainInfo.adv.mediaItemUrl : null;
  const carousels = data.wp.post.properties.assets.carousel;
  const qrCodes = data.wp.post.properties.assets.qrCode;
  const mp4 = data.wp.pages.nodes[1].home.video.mp4.mediaItemUrl;
  const webm = data.wp.pages.nodes[1].home.video.webm.mediaItemUrl;
  const secondsOfInactivity =
    data.wp.pages.nodes[1].home.video.secondsOfInactivity;

  useIdleTimer({
    timeout: secondsOfInactivity * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  return (
    <>
      <SEO title={title} />
      {showScreensaver && (
        <video
          className={"screensaver " + (animate ? "animate-class" : "")}
          autoPlay
          muted
          loop
        >
          <source src={webm} type="video/webm" />
          <source src={mp4} type="video/mp4" />
        </video>
      )}
      <div className="container">
        <UpdateContentModal
          hide={() => setShowUpdateModal(false)}
          show={showUpdateModal}
          updateContent={updateContent}
        />
        <div className="post-template">
          <div className="marg">
            <AniLink fade to="/" className="back-link">
              <img src={back} />
              <span>Back</span>
            </AniLink>
            <div className="post-template-content">
              <div className="imgs">
                <img src={icon} />
                {adv && (
                  <img className="adv" src={adv} />
                )}
              </div>
              <div className="intro">
                <h1 style={{ marginBottom: "8px" }}>{title}</h1>
                <p className="description">{content}</p>
              </div>

              <div className="qr-codes-wrapper">
                <p className="title">Download the app to your device now!</p>
                <div className="qr-codes-group">
                  <div className="qrcode-column">
                    <img
                      src={qrCodes.android.mediaItemUrl}
                      alt=""
                      className="qr-code-img"
                    />
                    <img src={androidIcon} alt="" className="icons" />
                  </div>
                  <div className="qrcode-column">
                    <img
                      src={qrCodes.ios.mediaItemUrl}
                      alt=""
                      className="qr-code-img"
                    />
                    <img src={iosIcon} alt="" className="icons" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel">
            {carousels.length > 0 ? (
              <Flickity
                className={"main-carousel"}
                elementType={"div"}
                options={{
                  prevNextButtons: true,
                  contain: true,
                  freeScroll: "true",
                }}
              >
                {carousels.map((item, i) => (
                  <img
                    src={item.image.mediaItemUrl}
                    key={i}
                    className="carousel-cell"
                  />
                ))}
              </Flickity>
            ) : (
              <img src={spinner} className="spinner" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query GetPost($slug: ID!) {
    wp {
      post(idType: SLUG, id: $slug) {
        title
        properties {
          mainInfo {
            content
            icon {
              mediaItemUrl
            }
            adv {
              mediaItemUrl
            }
          }
          assets {
            carousel {
              image {
                mediaItemUrl
              }
            }
            qrCode {
              iosLink
              androidLink
              android {
                mediaItemUrl
              }
              ios {
                mediaItemUrl
              }
            }
          }
        }
      }
      pages {
        nodes {
          home {
            video {
              secondsOfInactivity
              mp4 {
                mediaItemUrl
              }
              webm {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductTemplate;
